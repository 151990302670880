import React from 'react'

/* Components */
import {
	RolexPlaque,
	PatekPlaque,
	TudorPlaque,
} from '@components/plaques/Plaques'
import DesktopNav from '../DesktopNav/DesktopNav'
import SearchButton from '../SearchButton/SearchButton'
import ShoppingBag from '../ShoppingBag/ShoppingBag'
import SwitchLink from '@components/seo/SwitchLink'
import BrandLogo from '@components/logo/BrandLogo'

/* Mocks */
import { NavigationData, TextItems } from '@lib/mock/nav.models'

/* Helpers */
import {
	routeLocations,
	routeContact,
	routeAccount,
	routeLogin,
	routeWishlist,
} from '@helpers/routeHelper'

/* Styles */
import DesktopBannerStyles from './DesktopBanner.module.scss'

/* Hooks */
import usePlaques from '../../plaques/usePlaques'
import { useIsRegistered } from '@lib/hooks/useIsRegistered'

const DesktopBanner = ({
	data,
	text,
}: {
	data: NavigationData
	text: TextItems
}) => {
	/* Registered? */
	const isRegistered: boolean = useIsRegistered()

	/* Plaques */
	const plaques = usePlaques()
	const plaquesSection = () => {
		const plaquesToDisplay = plaques.map((plaque) => {
			switch (plaque) {
				case 'patek':
					return <PatekPlaque variant={`desktop`} />
				case 'tudor':
					return <TudorPlaque />
				case 'rolex':
					return <RolexPlaque />
				default:
					return <div></div>
			}
		})

		if (plaquesToDisplay.length !== 2) return null

		return (
			<>
				<div className={`${DesktopBannerStyles['logo-and-plaques__left']}`}>
					{plaquesToDisplay[0]}
				</div>
				<BrandLogo variant={`176x100`} />
				<div className={`${DesktopBannerStyles['logo-and-plaques__right']}`}>
					{plaquesToDisplay[1]}
				</div>
			</>
		)
	}

	return (
		<div className={`desktop-banner ${DesktopBannerStyles['container']}`}>
			<div className={`${DesktopBannerStyles['main-banner-container']}`}>
				<nav
					aria-label="Main"
					className={`app-max-width ${DesktopBannerStyles['main-banner']}`}
				>
					<div className={`${DesktopBannerStyles['main-banner__items']}`}>
						<SwitchLink
							href={routeLocations()}
							className={`${DesktopBannerStyles['main-banner__text']}`}
						>
							{text.locations}
						</SwitchLink>
						<SwitchLink
							href={routeContact()}
							className={`${DesktopBannerStyles['main-banner__text']}`}
						>
							{text.contact}
						</SwitchLink>
					</div>
					<div className={`${DesktopBannerStyles['main-banner__items']}`}>
						<SearchButton
							type="withText"
							text={text.search}
							variant={`primary`}
						/>
						<SwitchLink
							href={isRegistered ? routeAccount() : routeLogin()}
							className={`${DesktopBannerStyles['main-banner__text']}`}
							dataAttributes={{
								link: isRegistered ? 'account' : 'login',
							}}
						>
							{text.account}
						</SwitchLink>
						<SwitchLink
							href={routeWishlist()}
							className={`${DesktopBannerStyles['main-banner__text']}`}
						>
							{text.wishlist}
						</SwitchLink>
						<ShoppingBag variant={'primary'} isMobile={false} />
					</div>
				</nav>
			</div>
			<div className={`app-max-width ${DesktopBannerStyles['lower-banner']}`}>
				<div className={`${DesktopBannerStyles['logo-and-plaques']}`}>
					{plaquesSection()} {/* TODO: Fix laggieness caused by these images */}
				</div>
				<DesktopNav data={data} type="full" />
			</div>
		</div>
	)
}

export default DesktopBanner
