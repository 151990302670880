import React, { useEffect, useCallback, Suspense, lazy } from 'react'

/* Components */
const SiteFooter = lazy(() =>
	import('@components/footer/Footer').catch(() => {
		return { default: () => <></> }
	})
)

/* Shared Types */
import { DataAccordionsType } from '@components/footer/FooterAccordion.models'

/* Helpers */
import { getCustomObjectRecord } from '@helpers/ocapiCustomAPIHelper'

/* Hooks */
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'
import { useFirstRenderOnScroll } from '@lib/hooks/useFirstRenderOnScroll'

const Footer = (): React.ReactElement => {
	/* Render on first scroll down >100px */
	const render = useFirstRenderOnScroll(100)

	/* Fetch Accordions */
	const [accordions, setAccordions] = useStateChangeAttempt(null)

	/* TODO: move hookAccordionData to accordions data inside /api/object/index.ts */

	/* Grab data once per SPA instance */
	const getAccordions = useCallback(async () => {
		if (render) {
			const data = await getCustomObjectRecord('1916Strapi', 'accordions')
			if (data && data.c_content) {
				setAccordions(data)
			}
		}
	}, [render])

	/* Fetch Data on Render */
	useEffect(() => {
		if (render && getAccordions) {
			getAccordions()
		}
	}, [render, getAccordions])

	return (
		<>
			{render && accordions && (
				<Suspense fallback={<></>}>
					<SiteFooter accordions={accordions as DataAccordionsType} />
				</Suspense>
			)}
		</>
	)
}

export default Footer
