/* PWA Hooks */
import { useCategory } from '@salesforce/commerce-sdk-react'

/* Hooks */
import { hookNav } from '@helpers/navHelper/navHelper.hooks'

/* Fixed Constants */
const isServer: boolean = typeof window === 'undefined'

/* Shared Types */
import { NavigationData } from '@lib/mock/nav.models'

/* Local Types */
interface ReturnType {
	isLoading: boolean
	data: NavigationData
}

export const use1916Categories = (): ReturnType => {
	const { isLoading, data } = useCategory(
		{
			parameters: { id: 'root', levels: 2 },
		},
		{
			enabled: isServer,
		}
	)

	return {
		isLoading: isLoading,
		data: data ? hookNav(data, {}) : ({} as NavigationData),
	}
}
