import { useRouter } from '@next/navigation'

/* Local Types */
type OpenLinkEvent = React.MouseEvent<HTMLButtonElement>
type OpenLinkReturn = (e?: OpenLinkEvent) => void | undefined

/* Hook to open a link in new window, router, same window, or stay in router */
export const useOpenLink = (
	href: string | undefined,
	forceNewWindow?: boolean
): OpenLinkReturn => {
	const router = useRouter()

	/* No link? */
	if (!href) {
		return (): void => {
			return
		}
	}

	/* Determine how to open the link */
	return (e?: OpenLinkEvent): void => {
		if (href) {
			e && e.preventDefault()
			const newWindow: boolean =
				forceNewWindow ||
				(e && (e.shiftKey || e.ctrlKey || e.metaKey || e.altKey)) ||
				false
			if (newWindow) {
				window.open(href, '_blank')
			} else if (href.indexOf('//') == 0 || href.indexOf('/') !== 0) {
				window.location.href = href
			} else {
				router.push(href)
			}
		}
	}
}
