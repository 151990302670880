import { createContext, useContext } from 'react'

/* Local Types */
type HumanContextValues = boolean
export type HumanContextType =
	| [
			HumanContextValues,
			React.Dispatch<React.SetStateAction<HumanContextValues>>,
	  ]
	| [null, null]

/* Human Context Provider */
export const HumanContext = createContext<HumanContextType | null>(null)

/* Human Context Hook */
export const useHumanContext = (): HumanContextType => {
	const currentHumanContext = useContext(HumanContext)

	if (!currentHumanContext) {
		throw new Error(
			'useHumanContext has to be used within <HumanContext.Provider>'
		)
	}
	return currentHumanContext
}
