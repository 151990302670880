import React, { useEffect, useRef, useState } from 'react'

/* Components */
import { Icon } from '@lib/components/Icon/Icon'
import { isServer } from '@lib/utils/baseUtils'
import { Btn } from '@lib/components/Btn/Btn'
import { BtnLink } from '@lib/components/Btn/BtnLink'

/* Hooks */
import { useDisablePrompts } from '@hooks/useDisablePrompts'

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'

/* Contexts */
import { useHumanContext } from '@lib/context/human'

/* Styles */
import MobileBannerStyles from './MobileAppBanner.module.scss'

/* Local Constants */
const oneLink: string = 'https://onelink.to/4tcv6y'
const appLogo: string =
	'https://the1916company.imgix.net/App_logo.png?auto=format,compress&w=100'
const storageID: string = 'appBannerClosedDate'

/* Local Helpers */
const hasUserClosedBanner = () => {
	try {
		const bannerClosedDate = localStorage.getItem(storageID)
		const ttl = 1000 * 60 * 60 * 24 * 3 // 3 days

		if (bannerClosedDate) {
			const currentDate = new Date().getTime()
			const closedDate = parseInt(bannerClosedDate, 10)

			if (currentDate - closedDate < ttl) {
				return true
			}
		}
	} catch (err) {}

	return false
}

const checkIsSafari = () => {
	if (isServer) return false

	const { userAgent } = navigator

	const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(userAgent)
	const isSafari =
		/Safari/.test(userAgent) && !/Chrome|CriOS|FxiOS/.test(userAgent)

	return isAppleDevice && isSafari
}

const checkIsAndroidChrome = () => {
	if (isServer) return false

	const { userAgent } = navigator

	const isChrome =
		/Safari/.test(userAgent) && /Chrome/.test(userAgent) && !!window.chrome
	const isAndroid = /Android/.test(userAgent)

	return isChrome && isAndroid
}

/* Local Types */
interface BeforeInstallPromptEvent extends Event {
	readonly platforms: string[]
	readonly userChoice: Promise<{
		outcome: 'accepted' | 'dismissed'
		platform: string
	}>
	prompt(): Promise<void>
}

/* Global Types */
declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent
	}
}

/* Props */
interface MobileAppBannerProps {
	isAppBannerVisible: boolean
	setIsAppBannerVisible: (isVisible: boolean) => void
}

const MobileAppBanner = ({
	isAppBannerVisible,
	setIsAppBannerVisible,
}: MobileAppBannerProps): React.ReactElement | null => {
	/* State */
	const [ctaType, setCtaType] = useState<'androidBtn' | 'onelink' | null>(null)
	const [timeoutFinished, setTimeoutFinished] = useState(false)

	/* Refs */
	const deferredPromptRef = useRef<BeforeInstallPromptEvent | null>(null)

	/* Contexts */
	const isHuman = useHumanContext()?.[0]
	const disablePrompts = useDisablePrompts()

	/* Identify browser */
	const isSafari = checkIsSafari()
	const isAndroidChrome = checkIsAndroidChrome()

	/* Android Button or Link */
	useEffect(() => {
		/* Mobile third-party */
		if (!isSafari && !isAndroidChrome && !hasUserClosedBanner()) {
			setCtaType('onelink')
			setIsAppBannerVisible(true)
		}

		/* Set beforeinstallprompt event for Android Chrome with play store link fallback */
		if (isAndroidChrome && !hasUserClosedBanner()) {
			const waitForInstallPrompt = setTimeout(() => {
				// Fallback if beforeInstallPrompt doesn't fire
				setCtaType('onelink')
				setTimeoutFinished(true)
			}, 3000)

			const storeInstallPrompt = (e: BeforeInstallPromptEvent) => {
				clearTimeout(waitForInstallPrompt)

				/* Prevent Chrome 67 and earlier from automatically showing the prompt */
				e.preventDefault()

				/* Stash the event so it can be triggered later. */
				deferredPromptRef.current = e
				setCtaType('androidBtn')
				setIsAppBannerVisible(true)
			}

			window.addEventListener('beforeinstallprompt', storeInstallPrompt)

			return () => {
				window.removeEventListener('beforeinstallprompt', storeInstallPrompt)
			}
		}
	}, [])

	useEffect(() => {
		if (timeoutFinished && isHuman && !hasUserClosedBanner()) {
			setIsAppBannerVisible(true)
		}
	}, [timeoutFinished, isHuman])

	/* Disabled? */
	if (disablePrompts) {
		return null
	}

	/* Local Callbacks */
	const handleInstallClick = () => {
		if (deferredPromptRef.current !== null) {
			deferredPromptRef.current.prompt()
			/* Wait for the user to respond to the prompt */
			deferredPromptRef.current.userChoice.then(() => {
				deferredPromptRef.current = null
				setIsAppBannerVisible(false)
			})
		}
	}

	const handleClose = () => {
		try {
			const currentDate = new Date()
			localStorage.setItem(storageID, currentDate.getTime().toString())
		} catch (err) {}

		setIsAppBannerVisible(false)
	}

	/* Resources */
	const text: {
		[key: string]: string
	} = {}
	Object.entries({
		close: 'Close',
		heading: 'The 1916 Company',
		desc: 'Official App',
		alt: 'The 1916 Company Logo',
		androidBtn: 'Free on Google Play',
		androidBtnText: 'Install',
		onelink: 'Download For Free',
		oneLinkText: 'View',
	}).forEach(([key, value]) => {
		text[key] = getResource('appbanner', key, value)
	})

	/* UI Values */
	let cta: JSX.Element | null = null

	switch (ctaType) {
		case 'androidBtn':
			cta = (
				<Btn
					handleClick={handleInstallClick}
					className={MobileBannerStyles['play-elem__install-btn']}
				>
					{text['androidBtnText']}
				</Btn>
			)
			break
		case 'onelink':
			cta = (
				<BtnLink
					href={oneLink}
					className={MobileBannerStyles['play-elem__install-btn']}
				>
					{text['oneLinkText']}
				</BtnLink>
			)
			break
	}

	/* Use native smart app banner on Safari (Search 'meta name="apple-itunes-app"' in codebase to update) */
	if (isSafari || !isAppBannerVisible || hasUserClosedBanner() || !cta) {
		return null
	}

	return (
		<div
			id="playElem"
			className={`${MobileBannerStyles['play-elem']} u-hidden-desktop`}
		>
			<div className={MobileBannerStyles['play-elem__btn-wrapper']}>
				<Btn
					variant="icon"
					handleClick={handleClose}
					className={MobileBannerStyles['play-elem__close-btn']}
					ariaLabel={text['close']}
				>
					<Icon name="close" size="24" />
				</Btn>
			</div>
			<div className={MobileBannerStyles['play-elem__img-wrapper']}>
				<img
					src={appLogo}
					className={MobileBannerStyles['play-elem__img']}
					alt={text['alt']}
					width="50"
					height="50"
				/>
			</div>
			<div className={MobileBannerStyles['play-elem__content']}>
				<h2
					className={`${MobileBannerStyles['play-elem__txt']} ${MobileBannerStyles['play-elem__heading']}`}
				>
					{text['heading']}
				</h2>
				<h3
					className={`${MobileBannerStyles['play-elem__txt']} ${MobileBannerStyles['play-elem__desc']}`}
				>
					{text['desc']}
				</h3>
				{ctaType && (
					<p
						className={`${MobileBannerStyles['play-elem__txt']} ${MobileBannerStyles['play-elem__desc']}`}
					>
						{text[ctaType]}
					</p>
				)}
			</div>
			<div className={MobileBannerStyles['play-elem__btn-wrapper']}>{cta}</div>
		</div>
	)
}

export default MobileAppBanner
