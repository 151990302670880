import React, { useContext, useEffect, useRef, useState } from 'react'

/* Context */
import { SiteHeaderContext } from '../SiteHeaderProvider'

/* Components */
import { Icon } from '../../Icon/Icon'
import { Btn } from '../../Btn/Btn'
import BrandLogo from '@components/logo/BrandLogo'
import ShoppingBag from '../ShoppingBag/ShoppingBag'
import MobileAppBanner from '@components/banners/MobileAppBanner/MobileAppBanner'

/* Shared Types */
import { TextItems } from '@lib/mock/nav.models'

/* Styles */
import MobileBannerStyles from './MobileBanner.module.scss'

const MobileBanner = ({ text }: { text: TextItems }) => {
	const { isMobileNavOpen, setIsMobileNavOpen, showSearch, setShowSearch } =
		useContext(SiteHeaderContext)

	const [isAppBannerVisible, setIsAppBannerVisible] = useState(false)

	/* Local Handlers */
	const toggleSearch = (): void => {
		setShowSearch(!showSearch)
	}
	const openSearch = (): void => {
		setShowSearch(true)
	}
	const openNav = (): void => {
		setIsMobileNavOpen(true)
	}
	const closeNav = (): void => {
		setIsMobileNavOpen(false)
	}

	/* Refs */
	const mobilePlaceholder = useRef<HTMLDivElement>(null)
	const mobileBanner = useRef<HTMLDivElement>(null)

	/* Set mobile-placeholder to same height as mobile-banner */
	const useMatchHeight = (
		fromRef: React.RefObject<HTMLDivElement>,
		toRef: React.RefObject<HTMLDivElement>
	) => {
		/* Events to trigger height match */
		const renderEvents: string[] = ['resize', 'load']

		/* Set height of mobile-placeholder to current height of mobile-banner */
		const onRender = () => {
			if (fromRef?.current && toRef?.current) {
				fromRef.current.style.position = 'fixed'
				toRef.current.style.display = 'block'
				toRef.current.style.height = `${fromRef.current.clientHeight}px`
			}
		}

		/* Add event listeners */
		useEffect(() => {
			onRender()
			renderEvents.forEach((event) => window.addEventListener(event, onRender))
			return () => {
				renderEvents.forEach((event) =>
					window.removeEventListener(event, onRender)
				)
			}
		}, [])

		/* Update height on mobile-nav open/close */
		useEffect(() => {
			onRender()
		}, [isMobileNavOpen, isAppBannerVisible])
	}
	useMatchHeight(mobileBanner, mobilePlaceholder)

	return (
		<>
			<div
				ref={mobilePlaceholder}
				className={`mobile-placeholder ${MobileBannerStyles['placeholder']} ${
					isMobileNavOpen ? MobileBannerStyles['placeholder--hide'] : ''
				}`}
				style={{
					display: 'none',
				}}
			/>
			<div
				ref={mobileBanner}
				style={{
					position: 'sticky',
				}}
				className={`mobile-banner ${MobileBannerStyles['container']} ${
					isMobileNavOpen ? MobileBannerStyles['container--relative'] : ''
				}`}
			>
				<MobileAppBanner
					isAppBannerVisible={isAppBannerVisible}
					setIsAppBannerVisible={setIsAppBannerVisible}
				/>
				<div className={`${MobileBannerStyles['mobile-banner']}`}>
					<div
						className={`${MobileBannerStyles['mobile-banner__items']} ${MobileBannerStyles['mobile-banner__left']}`}
					>
						{isMobileNavOpen ? (
							<>
								<Btn
									variant={'icon'}
									handleClick={closeNav}
									ariaLabel={text.close}
								>
									<Icon
										name={'close'}
										size="24"
										title={text.close}
										variant={'secondary'}
									/>
								</Btn>
								<Btn
									variant={'icon'}
									handleClick={openSearch}
									ariaLabel={text.search}
								>
									<Icon
										name={'navSearch'}
										size={'24'}
										title={text.search}
										variant={'secondary'}
									/>
								</Btn>
							</>
						) : (
							<Btn variant="icon" handleClick={openNav} ariaLabel={text.menu}>
								<Icon
									name={'menu'}
									size={'24'}
									title={text.menu}
									variant={'secondary'}
								/>
							</Btn>
						)}
					</div>
					<BrandLogo variant={`60x35`} />
					<div className={`${MobileBannerStyles['mobile-banner__right']}`}>
						<ShoppingBag variant={'secondary'} />
					</div>
				</div>
				{isMobileNavOpen ? null : (
					<div className={`${MobileBannerStyles['search']}`}>
						<Btn
							className={`${MobileBannerStyles['search__button']}`}
							variant={`icon`}
							handleClick={toggleSearch}
							ariaLabel={text.search}
						>
							<div className={`${MobileBannerStyles['search__bar']}`}>
								<Icon
									name={'navSearch'}
									size="20"
									title={text.search}
									className={`${MobileBannerStyles['search__icon']}`}
								/>
								<span className={`${MobileBannerStyles['search__text']}`}>
									{text.search}
								</span>
							</div>
						</Btn>
					</div>
				)}
			</div>
		</>
	)
}

export default MobileBanner
