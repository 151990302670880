import React, { Suspense, lazy } from 'react'

/* Third Party Global Components */
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ThirdParty from '@components/third-party/ThirdParty'

/* Hooks */
import { useFirstRenderOnScroll } from '@hooks/useFirstRenderOnScroll'
import { useHasCookie, useIncrementCookie } from '@lib/hooks/useCookieHooks'
import { useDisablePrompts } from '@hooks/useDisablePrompts'

/* Custom Global Components */
const SubscribeModal = lazy(() =>
	import('@components/subscribe-modal/SubscribeModal').catch(() => {
		return { default: () => <></> }
	})
)

/* Third Party Styles */
import '../../scss/third-party/grecaptcha.scss'

/* Global Styles */
import '../../scss/global/global.scss'
import '../../scss/global/reset.scss'
import '../../scss/global/utilities.scss'

const SiteTemplate = ({
	children,
	thirdParty,
	reCaptcha,
}: {
	children: React.ReactNode
	thirdParty: { [key: string]: string }
	reCaptcha: { [key: string]: string }
}): React.ReactElement => {
  const disablePrompts = useDisablePrompts()

	/* Render subscribe modal if scroll interaction detected & no existing subscribe cookie */
	const hasCookie = useHasCookie('subscribe', undefined, true)
	const render = useFirstRenderOnScroll(300)

	/* Track page count to render subscribe modal after 2+ pages */
	const pageCounter: string = 'pageCounter'
	const pageCounterMin: number = 2
	useIncrementCookie(pageCounter, pageCounterMin)
	const hasPageCounter = useHasCookie(pageCounter, pageCounterMin, true, true)

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={reCaptcha.publicKey}
			language={'en'}
			useRecaptchaNet={false}
			scriptProps={{
				async: true,
				defer: true,
			}}
		>
			<ThirdParty thirdParty={thirdParty} />
			{children}
			{!disablePrompts && render && !hasCookie && hasPageCounter && (
				<Suspense fallback={<></>}>
					<SubscribeModal />
				</Suspense>
			)}
		</GoogleReCaptchaProvider>
	)
}

export default SiteTemplate
