import React from 'react'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { routeHome } from '@helpers/routeHelper'

/* Props */
type BrandLogoProps = {
	variant: '176x100' | '48x49' | '116x68' | '60x35' | '100x60' | '181x102'
}

/* Guide
    - 176x100: desktop full header
    - 48x49: desktop mini header
    - 116x68: desktop footer

    - 60x35: mobile header
    - 100x60: mobile footer

    - 181x102: error page
*/

const BrandLogo = ({ variant }: BrandLogoProps): React.ReactElement | null => {
	/* Dimensions */
	const [width, height] = variant.split('x')

	return (
		<SwitchLink
			style={{
				display: 'flex',
				flex: '0',
			}}
			href={routeHome()}
			aria-label={`Home`}
		>
			<img
				src={`/image/logo-${variant}.svg`}
				loading="lazy"
				alt={`The 1916 Company luxury watches for sale`}
				width={width}
				height={height}
				style={{
					width: width + 'px',
					height: height + 'px',
					maxWidth: width + 'px',
				}}
			/>
		</SwitchLink>
	)
}

export default BrandLogo
