import { useEffect } from 'react'

/* Hooks */
import { useStateChangeAttempt } from './useStateChangeAttempt'

/* Render if scrolled down or bottom is nearly in view */
export const useFirstRenderOnScroll = (offset?: number): boolean => {
	const [render, setRender] = useStateChangeAttempt(false)

	/* Default offset */
	offset = offset || 10

	/* Bottom threshold for initial page render */
	const bottomThreshold: number = 50

	useEffect(() => {
		/* If bottom is in view, no scroll is possible */
		const isBottomView = (): boolean => {
			return (
				window.innerHeight + window.scrollY >=
				document.documentElement.scrollHeight - bottomThreshold
			)
		}

		/* Check render eligibility */
		const handleScroll = (): boolean => {
			if (!render && (window.scrollY > offset || isBottomView())) {
				/* Allow render and remove listeners */
				setRender(true)
				setListener(false)
				return true
			}

			return false
		}

		/* Check after css is ready */
		const handleCSSReady = () => {
			setTimeout(handleScroll, 50)
		}

		/* Set listeners */
		const setListener = (add: boolean): void => {
			document.body[`${add ? 'add' : 'remove'}EventListener`](
				'css:ready',
				handleCSSReady
			)
			;['scroll', 'resize'].forEach((event) => {
				window[`${add ? 'add' : 'remove'}EventListener`](event, handleScroll)
			})
		}

		/* Check on mount */
		if (!handleScroll()) {
			setListener(true)
		}

		/* Cleanup */
		return () => {
			setListener(false)
		}
	}, [])

	return render as boolean
}
