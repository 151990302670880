import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'

/* Components */
import { Icon } from '@components/Icon/Icon'

/* Contexts */
import { useBasketContext } from '@lib/context/basket'

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'
import { routeBag } from '@helpers/routeHelper'

/* Styles */
import ShoppingBagStyles from './ShoppingBag.module.scss'
import { useIsWiderThan } from '@lib/hooks/useIsDesktop'

/* Local Types */
type ShoppingBagProps = {
	variant: 'primary' | 'secondary'
	isMobile?: boolean
}

/* Lazy Components */
const MiniCart = lazy(() => import('../MiniCart/MiniCart'))

/* Local Resources */
const text: { [key: string]: string } = {
	bag: getResource('nav', 'bag', 'Shopping Bag'),
}

const ShoppingBag = ({
	variant = 'primary',
	isMobile = true,
}: ShoppingBagProps) => {
	const [showMiniCart, setShowMiniCart] = useState(false)
	const [basket] = useBasketContext()
	const carePlanCount = basket?.items?.['care-plan'] ?? 0
	const basketNum: number | null =
		basket && basket.total >= 0 ? basket.total - carePlanCount : null
	const prevBasketNum = useRef(basketNum)

	// Prevent duplicate components while we have multiple headers. Either condense to
	// one header or detach the mini cart from the header, and then remove this logic.
	const isWiderThan1200 = useIsWiderThan(1200)
	const canShowMiniCart =
		(isMobile && !isWiderThan1200) || (!isMobile && isWiderThan1200)

	useEffect(() => {
		if (basketNum !== null && prevBasketNum.current !== basketNum) {
			if (prevBasketNum.current !== null && prevBasketNum.current < basketNum) {
				setShowMiniCart(true)
			}

			prevBasketNum.current = basketNum
		}
	}, [basketNum])

	return (
		<>
			<a
				href={routeBag()}
				className={`${ShoppingBagStyles['icon']}`}
				aria-label={text.bag}
			>
				<Icon
					name={'shoppingBag'}
					size={'24'}
					title={text.bag}
					variant={variant}
				/>
				<span
					className={`${ShoppingBagStyles['icon__shop-count']} minicart-quantity`}
					data-testid="minicart-quantity"
				>
					{!basketNum || basketNum == 0
						? ''
						: basketNum < 10
						  ? `${basketNum}`
						  : '9+'}
				</span>
			</a>
			{basket && canShowMiniCart && showMiniCart && (
				<Suspense fallback={null}>
					<MiniCart
						lineItems={basket.basket.items}
						setShowMiniCart={setShowMiniCart}
					/>
				</Suspense>
			)}
		</>
	)
}

export default ShoppingBag
